import { blogImg1, blogImg2, blogImg3 } from './imports';

const blogData = [
  {
    img: blogImg1,
    header: 'No comfort do written conduct at prevent manners on.',
    title: 'No comfort',
  },
  {
    img: blogImg2,
    header: 'Celebrated contrasted discretion him sympathize.',
    title: 'Celebrated',
  },
  {
    img: blogImg3,
    header: 'Though wished merits or be use these smart rooms ham',
    title: 'Merits',
  },
  {
    img: blogImg1,
    header: 'Make a better website solution for your product.',
    title: 'Website',
  },
  {
    img: blogImg2,
    header: 'No comfort do written conduct at prevent manners on.',
    title: 'Books',
  },
  {
    img: blogImg3,
    header: 'Celebrated contrasted discretion him sympathize.',
    title: 'Bread',
  },
];

export { blogData };
